import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';

export const isAuthGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const token = authService.getToken();

  if (token) {
    const isValid = await authService.verifyToken(token);

    if (isValid) {
      router.navigate(['/']);
      return false;
    }

    return true;
  }

  return true;
};
