<main class="login">
  <form
    [formGroup]="loginForm"
    novalidate
    (submit)="onSubmit($event)"
    class="form"
  >
    <div class="login-image-container">
      <img
        src="/img/login.svg"
        alt="Imagen de login"
        class="login-image"
      />
    </div>

    <div class="input-group">
      <input
        type="email"
        id="user"
        placeholder="Usuario"
        formControlName="email"
        class="input"
      />

      @if (hasErrors('email', 'required')) {
        <span class="error">
          El usuario es requerido
        </span>
      }

      @if (hasErrors('email', 'email')) {
        <span class="error">
          El usuario debe ser un correo electrónico válido
        </span>
      }
    </div>

    <div class="input-group">
      <input
        type="password"
        id="password"
        placeholder="Contraseña"
        formControlName="password"
        class="input"
      />

      @if (hasErrors('password', 'required')) {
        <span class="error">
          La contraseña es requerida
        </span>
      }
    </div>

    <div class="submit-group">
      <button
        type="submit"
        [disabled]="loginForm.invalid"
        class="button"
      >
        Acceder
      </button>
    </div>
  </form>

  <app-copyright></app-copyright>
</main>
