import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chart-bar-icon',
  standalone: true,
  imports: [],
  templateUrl: './chart-bar-icon.component.svg',
})
export class ChartBarIconComponent {
  @Input() color: string = '#000000';
}
