import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth/auth.service';
import type { DocumentInStorage, Document as DocumentType } from '../../models/documents.model';

interface ResponseDocuments {
  data: DocumentType[];
}

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private url = `${environment.apiUrl}/administrative`;

  constructor() { }

  getDocuments(eventId: string): Observable<ResponseDocuments> {
    const token = this._authService.getToken()!;
    const documentsUrl = `${this.url}/events/${eventId}/documents_pdf/`;

    return this._http.get<ResponseDocuments>(documentsUrl, { headers: { Authorization: `Bearer ${token}` } });
  }

  getDocumentInStorage(url: string): Observable<DocumentInStorage> {
    const token = this._authService.getToken()!;
    const documentUrl = `${this.url}/${url}`;

    return this._http.get<DocumentInStorage>(documentUrl, { headers: { Authorization: `Bearer ${token}` } });
  }

  getServiceOrderPdf(eventId: string): Observable<DocumentInStorage> {
    const token = this._authService.getToken()!;
    const documentUrl = `${this.url}/events/${eventId}/pdf_order/`;

    return this._http.get<DocumentInStorage>(documentUrl, { headers: { Authorization: `Bearer ${token}` } }).pipe(
      catchError((error: any) => {
        if (error.status === 500) {
          console.error('Server error occurred:', error);
          return throwError(() => new Error('Internal Server Error. Please try again later.'));
        }

        return throwError(() => error);
      })
    );
  }

  getServiceCertificatePdf(eventId: string): Observable<DocumentInStorage> {
    const token = this._authService.getToken()!;
    const documentUrl = `${this.url}/events/${eventId}/pdf_certificate/`;

    return this._http.get<DocumentInStorage>(documentUrl, { headers: { Authorization: `Bearer ${token}` } }).pipe(
      catchError((error: any) => {
        if (error.status === 500) {
          console.error('Server error occurred:', error);
          return throwError(() => new Error('Internal Server Error. Please try again later.'));
        }

        return throwError(() => error);
      })
    );
  }
}
