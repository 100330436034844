import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { SessionService } from '../../../auth/services/session/session.service';
import { Event } from '../../models/events.model';
import { Task } from '../../models/mip/task.model';
import { Ticket } from '../../models/tickets/ticket.model';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private _sessionService = inject(SessionService);
  private _baseUrl = `${environment.apiUrl}/administrative`;

  getOpenTickets(): Observable<Ticket[]> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting open tickets'));
    }

    return this._http.get<Ticket[]>(
      `${this._baseUrl}/tickets/?customer=${session.customer.id}`,
      { headers }
    );
  }

  getPendingServices(): Observable<Event[]> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting pending services'));
    }

    // const customerId = session!.customer.id;
    // return this._http.get<Event[]>(`${this._baseUrl}/events/?customer=${customerId}`, { headers });

    return this._http.get<Event[]>(
      `${this._baseUrl}/events/`,
      { headers }
    );
  }

  getTasks(): Observable<Task[]> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting tasks'));
    }

    const customerId = session!.customer.id;

    return this._http.get<Task[]>(
      `${this._baseUrl}/tasks/tasks/?customer=${customerId}`,
      { headers }
    );

    /* return this._http.get<Task[]>(
      `${this._baseUrl}/tasks/tasks/`,
      { headers }
    ); */
  }
}
