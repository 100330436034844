export const STATUS_COLORS = {
  'Cancelado': '#F43F5E',
  'Confirmado': '#306BA9',
  'Creado': '#FFA500',
  'Pendiente': '#FFA500',
  'Programado': '#FFA500',
  'Comenzado': '#FFA500',
  'En progreso': '#FFA500',
  'Finalizado': '#10B981',
  'Completado': '#10B981',
  'Sincronizado': '#9CA3AF',
}
