import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-medal-icon',
  standalone: true,
  imports: [],
  templateUrl: './medal-icon.component.svg',
})
export class MedalIconComponent {
  @Input() color: string = '#000000';
}
