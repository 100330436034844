import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Session } from '../../../auth/models/session.model';
import { LogoutIconComponent } from '../../icons/logout-icon/logout-icon.component';
import { PasswordIconComponent } from '../../icons/password-icon/password-icon.component';
import { AuthService } from '../../../auth/services/auth/auth.service';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [
    LogoutIconComponent,
    PasswordIconComponent
  ],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.css'
})
export class AvatarComponent {
  @Input() profile!: Session;

  private _authService = inject(AuthService);
  private _router = inject(Router);

  logout(): void {
    this._authService.logout();
  }

  changePassword(): void {
    this._router.navigate(['/change-password']);
  }
}
