<app-layout>
  <div class="home-element">
    <h1 class="title">
      Bienvenido a PestwareApp
    </h1>

    <section class="cards-section">
      <div class="card-item">
        <h3>
          Servicios pendientes
        </h3>

        @if (pendingServices.length > 0) {
          @for (service of pendingServices; track service.id) {
            <a
              [routerLink]="['/event-details', service.id]"
              class="service"
            >
              <span class="service-folio">
                {{ service.folio }}
              </span>

              <div class="service-body">
                <p class="service-title">
                  {{ service.folio }} - {{ service.title }}
                </p>
              </div>
            </a>
          }
        } @else {
          <p class="no-items">
            No hay servicios pendientes
          </p>
        }

        <button
          class="button"
          (click)="handleNavToServices()"
        >
          <span>Ver todos</span>
          <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
        </button>
      </div>

      <div class="card-item">
        <h3>
          Tickets abiertos
        </h3>

        @if (openTickets.length > 0) {
          @for (ticket of openTickets; track ticket.id) {
            <article class="ticket">
              <span class="ticket-folio">
                {{ ticket.ticket_type.folio_key_setting }}
              </span>

              <div class="ticket-body">
                <p class="ticket-title">
                  {{ ticket.folio }} - {{ ticket.ticket_type.name }}
                </p>

                <p class="ticket-customer">
                  {{ ticket.employee.name }}
                </p>

                <p class="ticket-description">
                  {{ ticket.comments }}
                </p>
              </div>
            </article>
          }
        } @else {
          <p class="no-items">
            No hay tickets abiertos
          </p>
        }

        <button
          class="button"
          (click)="handleNavToTickets()"
        >
          <span>Ver todos</span>
          <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
        </button>
      </div>

      <div class="card-item">
        <h3>
          Tareas
        </h3>

        @if (tasks.length > 0) {
          @for (task of tasks; track task.id) {
            <article class="task">
              <p class="task-title">
                {{ task.title }}
              </p>

              <p class="task-description">
                {{ task.description }}
              </p>
            </article>
          }
        } @else {
          <p class="no-items">
            No hay tareas
          </p>
        }

        <button
          class="button"
          (click)="handleNavToTasks()"
        >
          <span>Ver todas</span>
          <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
        </button>
      </div>
    </section>
  </div>
</app-layout>
