<app-layout>
  <div class="home-element">
    @if (event) {
      <section>
        <h2 class="header">
          Detalles del servicio: <span class="header-title">{{ event.title }}</span>
        </h2>

        <div class="customer-info">
          <p class="section-title">Atención a</p>

          <p class="customer-details">
            <span class="customer-name">{{ event.customer.name }}</span>
            <br>
            {{ event.customer.address }}
            <br>
            Tel. {{ event.customer.phone }}
            <br>
            {{ event.customer.email }}
          </p>
        </div>

        <div class="event-details">
          <p class="detail"><span class="detail-title">Folio:</span> {{ event.folio }}</p>
          <p class="detail"><span class="detail-title">Tipo de evento:</span> {{ event.event_type.name }}</p>
          <p class="detail"><span class="detail-title">Plaga:</span> {{ event.plague[0].name }}</p>
          <p class="detail"><span class="detail-title">Tipo de servicio:</span> {{ event.service_type.name }}</p>
          <p class="detail"><span class="detail-title">Total:</span> {{ event.total | currency }}</p>
          <p class="detail"><span class="detail-title">Fecha de inicio:</span> {{ event.initial_date | date: 'dd/MM/yyyy' }}</p>
          <p class="detail"><span class="detail-title">Fecha de finalización:</span> {{ event.final_date | date: 'dd/MM/yyyy' }}</p>

          @if (event.comments && event.comments.length > 0) {
            <p class="detail"><span class="detail-title">Comentarios:</span> {{ event.comments }}</p>
          }
        </div>

        @if (inspections.length > 0) {
          <div class="divider-container"><p-divider /></div>

          <div>
            <h3>
              Inspección del lugar
            </h3>
          </div>

          <div class="card">
            <p-table
              [value]="inspections"
              styleClass="p-datatable-striped"
              [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="font-weight: bold;">Plaga</th>
                  <th style="font-weight: bold;">Áreas</th>
                  <th style="font-weight: bold;">Grado de infestación</th>
                  <th style="font-weight: bold;">Ciclos de vida</th>
                  <th style="font-weight: bold;">Evidencias</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-inspection>
                <tr>
                  <td>
                    {{ inspection.inspectionData[0].plague.name }}
                  </td>

                  <td>
                    {{ getInspectionAreas(inspection) }}
                  </td>

                  <td>
                    {{ inspection.inspectionData[0].infestation_degree.name }}
                  </td>

                  <td>
                    {{ getInspectionLifeCycles(inspection) }}
                  </td>

                  <td>
                    {{ getInspectionEvidences(inspection) }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }

        @if (conditions.length > 0) {
         <div class="divider-container"><p-divider /></div>

          <div>
            <h3>
              Condición del lugar
            </h3>
          </div>

          <div class="card">
            <p-table
              [value]="conditions"
              styleClass="p-datatable-striped"
              [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="font-weight: bold;">Nombre</th>
                  <th style="font-weight: bold;">Condiciones</th>
                  <th style="font-weight: bold;">Áreas</th>
                  <th style="font-weight: bold;">Acciones de prevención</th>
                  <th style="font-weight: bold;">Acciones de corrección</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-condition>
                <tr>
                  <td>
                    {{ condition.condition.name }}
                  </td>

                  <td>
                    {{ getConditionCleanings(condition) }}
                  </td>

                  <td>
                    {{ getConditionAreas(condition) }}
                  </td>

                  <td>
                    {{ condition.conditionCleanings[0].preventive_action }}
                  </td>

                  <td>
                    {{ condition.conditionCleanings[0].action_corrective }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }

        @if (stationCounts.length > 0) {
          <div class="divider-container"><p-divider /></div>

          <div>
            <h3>
              Monitoreo de estaciones
            </h3>
          </div>

          <div class="card">
            <p-table
              [value]="stationCounts"
              styleClass="p-datatable-striped"
              [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="font-weight: bold;">Estación</th>
                  <th style="font-weight: bold;">Condiciones</th>
                  <th style="font-weight: bold;">Acciones correctivas</th>
                  <th style="font-weight: bold;">Acciones de estación</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-stationCount>
                <tr>
                  <td>
                    {{ stationCount.station.station.name }}
                  </td>

                  <td>
                    {{ getStationConditions(stationCount) }}
                  </td>

                  <td>
                    {{ stationCount.station.EmployeeActivity}}
                  </td>

                  <td>
                    {{ getStationActivities(stationCount) }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }

        @if (controlAreas.length > 0) {
         <div class="divider-container"><p-divider /></div>

          <div>
            <h3>
              Monitoreo de áreas
            </h3>
          </div>

          <div class="card">
            <p-table
              [value]="controlAreas"
              styleClass="p-datatable-striped"
              [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="font-weight: bold;">Área</th>
                  <th style="font-weight: bold;">Actividades en el área</th>
                  <th style="font-weight: bold;">Condiciones del área</th>
                  <th style="font-weight: bold;">Plagas</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-control>
                <tr>
                  <td>
                    {{ control.areaActivity.nesting_area.name }}
                  </td>

                  <td>
                    {{ getControlActivities(control) }}
                  </td>

                  <td>
                    {{ getControlConditions(control) }}
                  </td>

                  <td>
                    {{ getControlPlagues(control) }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }

        @if (plagueControls.length > 0) {
          <div class="divider-container"><p-divider /></div>

          <div>
            <h3>
              Control de plagas
            </h3>
          </div>

          <div class="card">
            <p-table
              [value]="plagueControls"
              styleClass="p-datatable-striped"
              [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="font-weight: bold;">Nombre</th>
                  <th style="font-weight: bold;">Áreas</th>
                  <th style="font-weight: bold;">Producto</th>
                  <th style="font-weight: bold;">Registro de pesticida</th>
                  <th style="font-weight: bold;">Método de aplicación</th>
                  <th style="font-weight: bold;">Dosis</th>
                  <th style="font-weight: bold;">Cantidad</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-plagueControl>
                <tr>
                  <td>
                    {{ plagueControl.control.name }}
                  </td>

                  <td>
                    {{ getPlagueControlAreas(plagueControl) }}
                  </td>

                  <td>
                    {{ plagueControl.controlFormProducts[0].product.name }}
                  </td>

                  <td>
                    {{ plagueControl.controlFormProducts[0].product.pesticide_registration }}
                  </td>

                  <td>
                    {{ plagueControl.controlFormProducts[0].application_method.name }}
                  </td>

                  <td>
                    {{ plagueControl.controlFormProducts[0].dose.dose.name }}
                    {{ plagueControl.controlFormProducts[0].dose.quantity_one }} {{ plagueControl.controlFormProducts[0].dose.unit_product_one.name }} X{{ ' ' }}
                    {{ plagueControl.controlFormProducts[0].dose.quantity_tow }} {{ plagueControl.controlFormProducts[0].dose.unit_product_two.name }}
                  </td>

                  <td>
                    {{ plagueControl.controlFormProducts[0].dose.quantity_one}} {{ plagueControl.controlFormProducts[0].dose.unit_product_one.name }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }

        @if (actionPlans.length > 0) {
         <div class="divider-container"><p-divider /></div>

          <div>
            <h3>
              Plan de acción
            </h3>
          </div>

          <div class="card">
            <p-table
              [value]="actionPlans"
              styleClass="p-datatable-striped"
              [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="font-weight: bold;">Nombre</th>
                  <th style="font-weight: bold;">Calificación</th>
                  <th style="font-weight: bold;">Descripción</th>
                  <th style="font-weight: bold;">Causa raíz</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-actionPlan>
                <tr>
                  <td>
                    {{ actionPlan.folio }}
                  </td>

                  <td>
                    {{ actionPlan.qualification.name }}
                  </td>

                  <td>
                    {{ actionPlan.description }}
                  </td>

                  <td>
                    {{ actionPlan.root_cause.name }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }

        @if (tasks.length > 0) {
          <div class="divider-container"><p-divider /></div>

          <div>
            <h3>
              Tareas
            </h3>
          </div>

          <div class="card">
            <p-table
              [value]="tasks"
              styleClass="p-datatable-striped"
              [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="font-weight: bold;">Titulo</th>
                  <th style="font-weight: bold;">Descripción</th>
                  <th style="font-weight: bold;">Fecha</th>
                  <th style="font-weight: bold;">Tipo de tarea</th>
                  <th style="font-weight: bold;">Áreas</th>
                  <th style="font-weight: bold;">Comentarios</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-task>
                <tr>
                  <td>
                    {{ task.task.title }}
                  </td>

                  <td>
                    {{ task.task.description }}
                  </td>

                  <td>
                    {{ task.task.initial_date | date: 'dd/MM/yyyy' }} - {{ task.task.final_date | date: 'dd/MM/yyyy' }}
                  </td>

                  <td>
                    {{ task.task.task_type.name }}
                  </td>

                  <td>
                    {{ getTasksAreas(task) }}
                  </td>

                  <td>
                    {{ getTaskComments(task) }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }

        @if (payments.length > 0) {
         <div class="divider-container"><p-divider /></div>

          <div>
            <h3>
              Pago del servicio
            </h3>
          </div>

          <div class="card">
            <p-table
              [value]="payments"
              styleClass="p-datatable-striped"
              [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="font-weight: bold;">Forma de pago</th>
                  <th style="font-weight: bold;">Método de pago</th>
                  <th style="font-weight: bold;">Monto pagado</th>
                  <th style="font-weight: bold;">Comentarios</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-payment>
                <tr>
                  <td>{{ payment.payment_way.name }}</td>
                  <td>{{ payment.payment_method.name }}</td>
                  <td>{{ payment.amount }}</td>
                  <td>{{ payment.comments }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }

        @if (signatures.length > 0) {
          <div class="divider-container"><p-divider /></div>

          <div>
            <h3>
              Firma del cliente
            </h3>
          </div>

          <div class="card">
            <p-table
              [value]="signatures"
              styleClass="p-datatable-striped"
              [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="font-weight: bold;">Responsable</th>
                  <th style="font-weight: bold;">Firma</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-signature>
                <tr>
                  <td>
                    {{ signature.name }}
                  </td>

                  <td>
                    <img
                      [src]="signature.signature"
                      alt=""
                      width="120"
                    />
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }
      </section>
    } @else {
      <div class="loader-container">
        <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
      </div>
    }
  </div>
</app-layout>
