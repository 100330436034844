import { Component } from '@angular/core';
import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';

@Component({
  selector: 'app-mip-folders',
  standalone: true,
  imports: [LayoutComponent],
  templateUrl: './mip-folders.component.html',
  styleUrl: './mip-folders.component.css'
})
export class MipFoldersComponent {

}
