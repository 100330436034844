import { Component } from '@angular/core';
import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';

@Component({
  selector: 'app-inspections',
  standalone: true,
  imports: [LayoutComponent],
  templateUrl: './inspections.component.html',
  styleUrl: './inspections.component.css'
})
export class InspectionsComponent {

}
