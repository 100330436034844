import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { Event as EventType } from '../../models/events.model';
import { Task } from '../../models/mip/task.model';
import { Ticket } from '../../models/tickets/ticket.model';
import { StatsService } from '../../services/stats/stats.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    LayoutComponent,
    RouterLink
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {
  private _statsService = inject(StatsService);
  private _router = inject(Router);

  public openTickets: Ticket[] = [];
  public pendingServices: EventType[] = [];
  public tasks: Task[] = [];

  constructor() {
    this.loadOpenTickets();
    this.loadPendingServices();
    this.loadTasks();
  }

  loadOpenTickets(): void {
    this._statsService.getOpenTickets().subscribe({
      next: (tickets) => {
        this.openTickets = tickets;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  loadPendingServices(): void {
    const today = new Date();

    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const oneMonthAhead = new Date(today);
    oneMonthAhead.setMonth(today.getMonth() + 1);

    this._statsService.getPendingServices().subscribe({
      next: (services) => {
        this.pendingServices = services.filter((service: EventType) => {
          const initialDate = new Date(service.initial_date);
          const finalDate = new Date(service.final_date);

          const isStatusNotCompleted = service.status.name !== "Completado";
          const isInDateRange = initialDate >= oneMonthAgo && finalDate <= oneMonthAhead;

          return isStatusNotCompleted && isInDateRange;
        });
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  loadTasks(): void {
    this._statsService.getTasks().subscribe({
      next: (tasks) => {
        this.tasks = tasks;
        console.log('TAREAS: ', this.tasks);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  handleNavToServices(): void {
    this._router.navigate(['/services']);
  }

  handleNavToTickets(): void {
    this._router.navigate(['/tracings']);
  }

  handleNavToTasks(): void {
    this._router.navigate(['/']);
  }
}
