<main class="change-password">
  <form
    novalidate
    [formGroup]="changePasswordForm"
    class="form"
    (submit)="onSubmit($event)"
  >
    <h3 class="title">
      Cambiar contraseña
    </h3>

    <div class="input-group">
      <input
        type="password"
        id="password"
        placeholder="Contraseña actual"
        formControlName="currentPassword"
        class="input"
      />

      @if (hasErrors('currentPassword', 'required')) {
        <span class="error">
          La contraseña es requerida
        </span>
      }
    </div>

    <div class="input-group">
      <input
        type="password"
        id="password"
        placeholder="Nueva contraseña"
        formControlName="newPassword"
        class="input"
      />

      @if (hasErrors('newPassword', 'required')) {
        <span class="error">
          La nueva contraseña es requerida
        </span>
      }
    </div>

    <div class="input-group">
      <input
        type="password"
        id="password"
        placeholder="Confirmar nueva contraseña"
        formControlName="confirmPassword"
        class="input"
      />

      @if (hasErrors('confirmPassword', 'required')) {
        <span class="error">
          La confirmación de la nueva contraseña es requerida
        </span>
      }

      @if (hasPasswordMismatch()) {
        <span class="error">
          Las contraseñas no coinciden
        </span>
      }
    </div>

    <div class="submit-group">
      <button
        [disabled]="changePasswordForm.invalid"
        type="submit"
        class="button button-primary"
      >
        Cambiar contraseña
      </button>

      <button
        type="button"
        class="button button-secondary"
        (click)="goBack()"
      >
        Cancelar
      </button>
    </div>
  </form>
</main>
