import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { forkJoin } from 'rxjs';

import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { AreaCategory } from '../../models/tickets/area-category.model';
import { Employee } from '../../models/tickets/employee.model';
import { Group } from '../../models/tickets/group.model';
import { Media } from '../../models/tickets/media.model';
import { PlagueCategory } from '../../models/tickets/plague-category.model';
import { TicketType } from '../../models/tickets/ticket-type.model';
import { Ticket } from '../../models/tickets/ticket.model';
import { TicketsService } from '../../services/tickets/tickets.service';

interface Filter {
  label: string;
  value: string;
}

@Component({
  selector: 'app-tracings',
  standalone: true,
  imports: [
    ButtonModule,
    CalendarModule,
    CommonModule,
    DialogModule,
    DropdownModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    InputTextareaModule,
    LayoutComponent,
    MultiSelectModule,
    ReactiveFormsModule,
    TableModule,
    TagModule,
    ToastModule,
    TooltipModule,
  ],
  providers: [MessageService],
  templateUrl: './tracings.component.html',
  styleUrl: './tracings.component.css'
})
export class TracingsComponent implements OnInit {
  public tracingForm: FormGroup;
  private _ticketsService = inject(TicketsService);

  public tickets: Ticket[] = [];
  public comments: string = '';
  public ticketsTypes: TicketType[] = [];
  public media: Media[] = [];
  public employees: Employee[] = [];
  public groups: Group[] = [];
  public areaCategories: AreaCategory[] = [];
  public plagueCategories: PlagueCategory[] = [];
  public ticketTypeFilter: Filter[] = [];
  public mediaFilter: Filter[] = [];
  public groupFilter: Filter[] = [];
  public areaCategoryFilter: Filter[] = [];
  public plagueCategoryFilter: Filter[] = [];
  public loading: boolean = false;
  public isModalVisible: boolean = false;

  constructor(
    private _messageService: MessageService,
    private _formBuilder: FormBuilder
  ) {
    this.tracingForm = this._formBuilder.group({
      comments: ['', [Validators.required]],
      ticketType: ['', [Validators.required]],
      media: ['', [Validators.required]],
      employee: ['', [Validators.required]],
      group: ['', [Validators.required]],
      areaCategory: ['', [Validators.required]],
      plagueCategory: ['', [Validators.required]],
      closeDate: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.loadTickets();
    this.loadData();
  }

  loadTickets(): void {
    this.loading = true;

    this._ticketsService.getTickets().subscribe((data: Ticket[]) => {
      this.tickets = data;
      this.loading = false;
    });
  }

  loadData(): void {
    forkJoin({
      ticketTypes: this._ticketsService.getTicketTypes(),
      media: this._ticketsService.getMedia(),
      employees: this._ticketsService.getEmployees(),
      groups: this._ticketsService.getGroups(),
      areaCategories: this._ticketsService.getAreaCategories(),
      plagueCategories: this._ticketsService.getPlagueCategories(),
    }).subscribe({
      next: (results: any) => {
        this.ticketsTypes = results.ticketTypes;
        this.media = results.media;
        this.employees = results.employees;
        this.groups = results.groups;
        this.areaCategories = results.areaCategories;
        this.plagueCategories = results.plagueCategories;

        this.ticketTypeFilter = results.ticketTypes.map((type: any) => ({
          label: type.name,
          value: type.name
        }));

        this.mediaFilter = results.media.map((media: any) => ({
          label: media.name,
          value: media.name
        }));

        this.groupFilter = results.groups.map((group: any) => ({
          label: group.name,
          value: group.name
        }));

        this.areaCategoryFilter = results.areaCategories.map((category: any) => ({
          label: category.name,
          value: category.name
        }));

        this.plagueCategoryFilter = results.plagueCategories.map((category: any) => ({
          label: category.name,
          value: category.name
        }));
      },
      error: (error: any) => {
        console.error('Error al cargar los datos:', error);
      }
    });
  }

  handleSave(event: Event): void {
    event.preventDefault();

    if (this.tracingForm.valid) {
      this._ticketsService.createTicket(
        this.tracingForm.value.comments,
        this.tracingForm.value.ticketType,
        this.tracingForm.value.media,
        this.tracingForm.value.employee,
        this.tracingForm.value.group,
        this.tracingForm.value.areaCategory,
        this.tracingForm.value.plagueCategory,
        this.tracingForm.value.closeDate
      ).subscribe({
        next: (data: Ticket) => {
          this._messageService.add({
            severity: 'success',
            summary: '¡Éxito!',
            detail: '¡El ticket se ha creado correctamente!'
          });

          this.loadTickets();
          this.isModalVisible = false;
        },
        error: (error: any) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Hubo un problema al crear el ticket. Por favor, inténtelo de nuevo.'
          });

          console.error('Error al crear el ticket:', error);
        }
      });

      this.isModalVisible = false;
    } else {
      this.tracingForm.markAllAsTouched();
    }
  }

  hasErrors(field: string, typeError: string) {
    return this.tracingForm.get(field)?.hasError(typeError) && this.tracingForm.get(field)?.touched;
  }
}
