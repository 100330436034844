import { Injectable } from '@angular/core';
import Cookies from 'js-cookie';

import { Session } from '../../models/session.model';

const COOKIE_NAME = 'Swopyn-AUTH-TOKEN';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  getSession(): Session | null {
    try {
      const session = Cookies.get(COOKIE_NAME);
      return session ? JSON.parse(session) as Session : null;
    } catch (error) {
      return null;
    }
  }

  setSession(session: Session): boolean {
    try {
      Cookies.set(COOKIE_NAME, JSON.stringify(session), { expires: 30 });
      return true;
    } catch (error) {
      return false;
    }
  }

  clearSession(): boolean {
    try {
      Cookies.remove(COOKIE_NAME);
      return true;
    } catch (error) {
      return false;
    }
  }
}
