import { Component } from '@angular/core';
import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';

@Component({
  selector: 'app-areas',
  standalone: true,
  imports: [LayoutComponent],
  templateUrl: './areas.component.html',
  styleUrl: './areas.component.css'
})
export class AreasComponent {

}
