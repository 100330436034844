import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { forkJoin } from 'rxjs';

import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { ActionPlan } from '../../models/mip/action-plan.model';
import { EventRetrieve } from '../../models/mip/event-retrieve.model';
import { Payment } from '../../models/mip/payment.model';
import { Signature } from '../../models/mip/signature.model';
import { ConditionResponse, ControlAreasResponse, ControlPlagueResponse, InspectionResponse, MipDetailsService, StationCountsResponse, TaskResponse } from '../../services/mip/mip-details.service';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-event-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    DividerModule,
    TableModule
  ],
  templateUrl: './event-details.component.html',
  styleUrl: './event-details.component.css'
})
export class EventDetailsComponent implements OnInit {
  private _route = inject(ActivatedRoute);
  private _mipDetailsService = inject(MipDetailsService);

  public eventId: string | null = null;
  public event: EventRetrieve | null = null;
  public inspections: InspectionResponse[] = [];
  public conditions: ConditionResponse[] = [];
  public stationCounts: StationCountsResponse[] = [];
  public plagueControls: ControlPlagueResponse[] = [];
  public actionPlans: ActionPlan[] = [];
  public payments: Payment[] = [];
  public signatures: Signature[] = [];
  public tasks: TaskResponse[] = [];
  public controlAreas: ControlAreasResponse[] = [];

  ngOnInit(): void {
    this.eventId = this._route.snapshot.paramMap.get('id');

    if (this.eventId) {
      this.fetchEventData(this.eventId);
    }
  }

  fetchEventData(eventId: string): void {
    forkJoin({
      event: this._mipDetailsService.getEvent(eventId),
      inspections: this._mipDetailsService.getInspections(eventId),
      conditions: this._mipDetailsService.getConditions(eventId),
      stationCounts: this._mipDetailsService.getStationCounts(eventId),
      plagueControls: this._mipDetailsService.getControlPlagues(eventId),
      actionPlans: this._mipDetailsService.getActionPlans(eventId),
      payments: this._mipDetailsService.getPayments(eventId),
      signatures: this._mipDetailsService.getSignatures(eventId),
      tasks: this._mipDetailsService.getTasks(eventId),
      controlAreas: this._mipDetailsService.getControlAreas(eventId),
    }).subscribe({
      next: (results: any) => {
        console.log('RESULTS:', results);
        this.event = results.event.data;
        this.inspections = results.inspections;
        this.conditions = results.conditions;
        this.stationCounts = results.stationCounts;
        this.plagueControls = results.plagueControls;
        this.actionPlans = results.actionPlans;
        this.payments = results.payments;
        this.signatures = results.signatures;
        this.tasks = results.tasks;
        this.controlAreas = results.controlAreas;
      },
      error: (error: any) => {
        console.error('Error al cargar los datos:', error);
      }
    });
  }

  getInspectionAreas(inspection: InspectionResponse): string {
    return inspection.inspectionData[0].nesting_area.map((area) => area.name).join(', ');
  }

  getInspectionLifeCycles(inspection: InspectionResponse): string {
    return inspection.inspectionData[0].life_cycle_plague.map((plague) => plague.name).join(', ');
  }

  getInspectionEvidences(inspection: InspectionResponse): string {
    return inspection.inspectionData[0].evidence_type.map((evidence) => evidence.name).join(', ');
  }

  getConditionCleanings(condition: ConditionResponse): string {
    return condition.conditionCleanings.map(({ cleaning }) => cleaning.name).join(', ');
  }

  getConditionAreas(condition: ConditionResponse): string {
    return condition.conditionCleanings[0].nesting_area.map((area) => area.name).join(', ');
  }

  getStationConditions(station: StationCountsResponse): string {
    return station.station.station_condition.map((condition) => condition.name).join(', ');
  }

  getStationActivities(station: StationCountsResponse): string {
    return station.station.station_activity.map((activity) => activity.name).join(', ');
  }

  getPlagueControlAreas(control: ControlPlagueResponse): string {
    return control.controlFormProducts[0].nesting_area.map((area) => area.name).join(', ');
  }

  getTasksAreas(task: TaskResponse): string {
    return task.task.nesting_area.map((area) => area.name).join(', ');
  }

  getTaskComments(task: TaskResponse): string {
    return task.taskComments.map((comment) => comment.comment).join('; ');
  }

  getControlActivities(control: ControlAreasResponse): string {
    return control.areaActivity.activity_area.map((activity) => activity.name).join(', ');
  }

  getControlConditions(control: ControlAreasResponse): string {
    return control.areaActivity.condition_area.map((condition) => condition.name).join(', ');
  }

  getControlPlagues(control: ControlAreasResponse): string {
    return control.areaActivity.plague.map((plague) => plague.name).join(', ');
  }
}
