<main>
  <nav>
    <div class="nav-container">
      <picture>
        <a
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          class=""
        >
          <img
            src="/img/pestware_large.png"
            alt="Pestware Logo"
          />
        </a>
      </picture>

      <ul>
        <li class="list-item">
          <a
            routerLink="/services"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="link"
          >
            <app-calendar-icon [color]="'#5e72e4'"></app-calendar-icon>
            Servicios programados
          </a>
        </li>

        <li class="list-item">
          <a
            routerLink="/reports"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="link"
          >
            <app-chart-bar-icon [color]="'#11cdef'"></app-chart-bar-icon>
            Reportes de servicios
          </a>
        </li>

        <li class="list-item">
          <a
            routerLink="/tracings"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="link"
          >
            <app-ticket-icon [color]="'#2dce89'"></app-ticket-icon>
            Tickets
          </a>
        </li>

        <li class="list-item">
          <a
            routerLink="/mip-folders"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="link"
          >
            <app-folder-icon [color]="'#fb6340'"></app-folder-icon>
            Carpeta MIP
          </a>
        </li>

        <li class="list-item">
          <a
            routerLink="/inspections"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="link"
          >
            <app-medal-icon [color]="'#f5365c'"></app-medal-icon>
            Inspecciones
          </a>
        </li>

        <li class="list-item">
          <a
            routerLink="/areas"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="link"
          >
            <app-medal-icon [color]="'#8898aa'"></app-medal-icon>
            Áreas de inspecciones
          </a>
        </li>
      </ul>
    </div>

    <div class="avatar-container">
      @if (profile) {
        <app-avatar [profile]="profile"></app-avatar>
      }
    </div>
  </nav>

  <section>
    <div class="content-header"></div>

    <div class="content-section">
      <ng-content></ng-content>
    </div>
  </section>
</main>
