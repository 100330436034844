export function generateRandomColor(): [string, string] {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  const rgbaColor = `rgba(${r}, ${g}, ${b}, 0.5)`;
  const rgbColor = `rgb(${r}, ${g}, ${b})`;

  return [rgbaColor, rgbColor];
}
