import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-password-icon',
  standalone: true,
  imports: [],
  templateUrl: './password-icon.component.svg',
})
export class PasswordIconComponent {
  @Input() color = '#000000';
}
