<app-layout>
  <p-toast />
  
  <div class="home-element">
    <h2>
      Tickets de seguimiento
    </h2>

    <header>
      <p-button
        label="Nuevo ticket"
        icon="pi pi-plus"
        size="small"
        severity="success"
        (onClick)="isModalVisible = true"
      />
    </header>

    <section class="table-container">
      <p-table
        #dt2
        [value]="tickets"
        dataKey="id"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [paginator]="true"
        styleClass="p-datatable-striped"
        [globalFilterFields]="['folio', 'ticket_type.name', 'media.name', 'employee.name', 'group.name', 'close_date', 'area_category.name', 'plague_category.name', 'comments']"
        [tableStyle]="{ 'min-width': '125rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width:10%">#ID</th>
            <th style="width:11%">Tipo de ticket</th>
            <th style="width:11%">Media</th>
            <th style="width:13%">Empleado</th>
            <th style="width:11%">Grupo</th>
            <th style="width:11%">Fecha</th>
            <th style="width:11%">Categoría de área</th>
            <th style="width:11%">Categoría de plaga</th>
            <th style="width:11%">Comentarios</th>
          </tr>

          <tr>
            <th></th>

            <th>
              <p-columnFilter field="ticket_type.name" matchMode="equals" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown
                    [options]="ticketTypeFilter"
                    (onChange)="filter($event.value)"
                    placeholder="Tipo de ticket..."
                    [showClear]="true"
                  >
                    <ng-template let-option pTemplate="item">
                      <span class="state-tag">
                        {{ option.value }}
                      </span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>

            <th>
              <p-columnFilter field="media.name" matchMode="equals" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown
                    [options]="mediaFilter"
                    (onChange)="filter($event.value)"
                    placeholder="Media..."
                    [showClear]="true"
                  >
                    <ng-template let-option pTemplate="item">
                      <span class="state-tag">
                        {{ option.value }}
                      </span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>

            <th></th>

            <th>
              <p-columnFilter field="group.name" matchMode="equals" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown
                    [options]="groupFilter"
                    (onChange)="filter($event.value)"
                    placeholder="Grupo..."
                    [showClear]="true"
                  >
                    <ng-template let-option pTemplate="item">
                      <span class="state-tag">
                        {{ option.value }}
                      </span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>

            <th>
              <!--  -->
            </th>

            <th>
              <p-columnFilter field="area_category.name" matchMode="equals" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown
                    [options]="areaCategoryFilter"
                    (onChange)="filter($event.value)"
                    placeholder="Categoría de área..."
                    [showClear]="true"
                  >
                    <ng-template let-option pTemplate="item">
                      <span class="state-tag">
                        {{ option.value }}
                      </span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>

            <th>
              <p-columnFilter field="plague_category.name" matchMode="equals" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown
                    [options]="plagueCategoryFilter"
                    (onChange)="filter($event.value)"
                    placeholder="Categoría de plaga..."
                    [showClear]="true"
                  >
                    <ng-template let-option pTemplate="item">
                      <span class="state-tag">
                        {{ option.value }}
                      </span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>

            <th>
              <!--  -->
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-ticket>
          <tr>
            <td>
              {{ ticket.folio }}
            </td>

            <td>
              {{ ticket.ticket_type.name }}
            </td>

            <td>
              {{ ticket.media.name }}
            </td>

            <td>
              {{ ticket.employee.name }}
            </td>

            <td>
              {{ ticket.group.name }}
            </td>

            <td>
              {{ ticket.close_date | date: 'dd/MM/yyyy' }}
            </td>

            <td>
              {{ ticket.area_category.name }}
            </td>

            <td>
              {{ ticket.plague_category.name }}
            </td>

            <td>
              {{ ticket.comments }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="9">
              <p class="emptymessage">No se encontraron tickets</p>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </section>

    <p-dialog
      [modal]="true"
      [(visible)]="isModalVisible"
      [style]="{ width: '80%' }"
    >
      <ng-template pTemplate="header">
        <div class="dialog-header">
          <span class="dialog-header-title">
            Agregar nuevo ticket
          </span>
        </div>
      </ng-template>

      <form
        [formGroup]="tracingForm"
        novalidate
        (submit)="handleSave($event)"
        class="form"
      >
        <div class="form-group">
          <select
            name="ticketType"
            id="ticketType"
            formControlName="ticketType"
            class="form-select"
          >
            <option
              value=""
              disabled
            >
              Selecciona un tipo de ticket
            </option>

            @for (ticketType of ticketsTypes; track ticketType.id) {
              <option
                [value]="ticketType.id"
              >
                {{ ticketType.name }}
              </option>
            }
          </select>

          <select
            name="media"
            id="media"
            formControlName="media"
            class="form-select"
          >
            <option
              value=""
              disabled
            >
              Selecciona un media
            </option>

            @for (mediaItem of media; track mediaItem.id) {
              <option
                [value]="mediaItem.id"
              >
                {{ mediaItem.name }}
              </option>
            }
          </select>
        </div>

        <div class="form-group">
          <select
            name="employee"
            id="employee"
            formControlName="employee"
            class="form-select"
          >
            <option
              value=""
              disabled
            >
              Selecciona un empleado
            </option>

            @for (employee of employees; track employee.id) {
              <option
                [value]="employee.id"
              >
                {{ employee.name }}
              </option>
            }
          </select>

          <select
            name="group"
            id="group"
            formControlName="group"
            class="form-select"
          >
            <option
              value=""
              disabled
            >
              Selecciona un grupo
            </option>

            @for (group of groups; track group.id) {
              <option
                [value]="group.id"
              >
                {{ group.name }}
              </option>
            }
          </select>
        </div>

        <div class="form-group">
          <select
            name="areaCategory"
            id="areaCategory"
            formControlName="areaCategory"
            class="form-select"
          >
            <option
              value=""
              disabled
            >
              Selecciona una categoría de área
            </option>

            @for (areaCategory of areaCategories; track areaCategory.id) {
              <option
                [value]="areaCategory.id"
              >
                {{ areaCategory.name }}
              </option>
            }
          </select>

          <select
            name="plagueCategory"
            id="plagueCategory"
            formControlName="plagueCategory"
            class="form-select"
          >
            <option
              value=""
              disabled
            >
              Selecciona una categoría de plaga
            </option>

            @for (plagueCategory of plagueCategories; track plagueCategory.id) {
              <option
                [value]="plagueCategory.id"
              >
                {{ plagueCategory.name }}
              </option>
            }
          </select>
        </div>

        <div class="form-group">
          <input
            type="date"
            name="closeDate"
            id="closeDate"
            formControlName="closeDate"
            class="form-input"
            placeholder="Fecha de cierre"
          />
        </div>

        <div class="form-group">
          <textarea
            rows="5"
            placeholder="Comentarios"
            formControlName="comments"
            class="form-text-area"
          >
          </textarea>
        </div>

        <div class="button-group">
          <p-button
            label="Agregar ticket"
            icon="pi pi-check"
            size="small"
            severity="success"
            type="submit"
          />

          <p-button
            label="Cancelar"
            icon="pi pi-times"
            size="small"
            severity="danger"
            (onClick)="isModalVisible = false"
          />
        </div>
      </form>
    </p-dialog>
  </div>
</app-layout>
