import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { SessionService } from '../../../auth/services/session/session.service';
import { Event } from '../../models/events.model';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private _sessionService = inject(SessionService);
  // private url = `${environment.apiUrl}/administrative/events/?customer=`;
  private url = `${environment.apiUrl}/administrative/events/`;

  constructor() { }

  getEvents(): Observable<Event[]> {
    const token = this._authService.getToken()!;
    // const session = this._sessionService.getSession();
    // const customerId = session!.customer.id;

    // return this._http.get<Event[]>(`${this.url}${customerId}`, { headers: { Authorization: `Bearer ${token}` } });
    return this._http.get<Event[]>(this.url, { headers: { Authorization: `Bearer ${token}` } });
  }

  // getEvents(): Observable<Event[]> {}

  // getEventById(id: string): Observable<Event> {}

  // createEvent(event: Event): Observable<Event> {}

  // updateEvent(event: Event): Observable<Event> {}

  // deleteEvent(event: Event): Observable<void> {}
}
