import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { Profile } from '../../models/profile.model';
import { Session } from '../../models/session.model';
import type { Customer, User } from '../../models/user.model';
import { SessionService } from '../session/session.service';

interface ResponseProfile {
  data: Profile | {
    user: null;
  };
}

interface ResponseLogin {
  data: {
    access_token: string;
    refresh_token: string;
    type: string;
    user: User;
    customer: Customer;
    portal_user_id: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _http = inject(HttpClient);
  private _router = inject(Router);
  private _session = inject(SessionService);

  public user = signal<Session | null>(null);

  constructor() {
    this.user.set(this._session.getSession());
  }

  getToken(): string | null {
    try {
      const session = this._session.getSession();
      return session?.token || null;
    } catch (error) {
      return null;
    }
  }

  verifyToken(token: string): Promise<boolean> {
    try {
      this._http.get<ResponseProfile>(`${environment.apiUrl}/administrative/authentication/profile/`, { headers: { Authorization: `Bearer ${token}` } })
        .subscribe((data) => {
          const session = this._session.getSession();

          if (session) {
            this.user.set(session);
          }
        });

      return Promise.resolve(true);
    } catch (error) {
      return Promise.resolve(false);
    }
  }

  login(email: string, password: string): Promise<boolean> {
    try {
      this._http.post<ResponseLogin>(
        `${environment.apiUrl}/customers/authentication/login/`,
        {
          username: email,
          password
        }
      )
        .subscribe((data) => {
          const session = {
            token: data.data.access_token,
            user: data.data.user,
            customer: data.data.customer,
            portal_user_id: data.data.portal_user_id
          };

          this._session.setSession(session);
          this.user.set(session);

          this._router.navigate(['/']);
        });

      return Promise.resolve(true);
    } catch (error) {
      return Promise.resolve(false);
    }
  }

  logout(): Promise<boolean> {
    try {
      this._session.clearSession();
      this.user.set(null);
      this._router.navigate(['/login']);

      return Promise.resolve(true);
    } catch (error) {
      return Promise.resolve(false);
    }
  }

  get activeUser(): Session | null {
    return this.user();
  }
}
