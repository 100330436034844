import { Component, inject, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { Session } from '../../../auth/models/session.model';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { AvatarComponent } from '../../components/avatar/avatar.component';
import { AlarmIconComponent } from '../../icons/alarm-icon/alarm-icon.component';
import { CalendarIconComponent } from '../../icons/calendar-icon/calendar-icon.component';
import { ChartBarIconComponent } from '../../icons/chart-bar-icon/chart-bar-icon.component';
import { FolderIconComponent } from '../../icons/folder-icon/folder-icon.component';
import { MedalIconComponent } from '../../icons/medal-icon/medal-icon.component';
import { TicketIconComponent } from '../../icons/ticket-icon/ticket-icon.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    AvatarComponent,
    CalendarIconComponent,
    ChartBarIconComponent,
    AlarmIconComponent,
    FolderIconComponent,
    MedalIconComponent,
    TicketIconComponent
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css'
})
export class LayoutComponent implements OnInit {
  private _authService = inject(AuthService);
  public profile: Session | null = null;

  ngOnInit(): void {
    this.profile = this._authService.activeUser;
  }
}
