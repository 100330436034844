<app-layout>
  <div class="home-element">
    <h2>
      Reportes y estadísticas
    </h2>

    <form
      [formGroup]="reportsForm"
      novalidate
      (submit)="onSubmit($event)"
      class="form"
    >
      <figcaption class="form-figcaption">
        Puedes filtrar los reportes por fecha
      </figcaption>

      <div class="form-group">
        <p-calendar
          formControlName="initialDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder="Fecha de inicio"
        />

        <p-calendar
          formControlName="finalDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder="Fecha de final"
        />

        <button
          type="submit"
          class="button"
        >
          Filtrar reportes
        </button>
      </div>
    </form>

    @if (!(
      inspectionPlagueCategories == null &&
      inspectionPlagues == null &&
      inspectionLifeCycles == null &&
      inspectionEvidences == null &&
      inspectionDegrees == null
    )) {
      <section class="section">
        <h3 class="section-title">
          Inspecciones
        </h3>

        <div class="inspections-graphs">
          @if (inspectionPlagueCategories) {
            <article class="inspection-graph">
              <h4 class="inspection-graph-title">
                Proporción de Tipos de Plaga en Inspecciones
              </h4>

              <div class="inspection-graph-canvas">
                <canvas baseChart [data]="inspectionPlagueCategories" type="pie">
                </canvas>
              </div>
            </article>
          }

          @if (inspectionPlagues) {
            <article class="inspection-graph">
              <h4 class="inspection-graph-title">
                Proporción de Plagas en Inspecciones
              </h4>

              <div class="inspection-graph-canvas">
                <canvas baseChart [data]="inspectionPlagues" type="pie">
                </canvas>
              </div>
            </article>
          }

          @if (inspectionLifeCycles) {
            <article class="inspection-graph">
              <h4 class="inspection-graph-title">
                Proporción de Ciclos de Vida en Inspecciones
              </h4>

              <div class="inspection-graph-canvas">
                <canvas baseChart [data]="inspectionLifeCycles" type="pie">
                </canvas>
              </div>
            </article>
          }

          @if (inspectionEvidences) {
            <article class="inspection-graph">
              <h4 class="inspection-graph-title">
                Proporción de Evidencias en Inspecciones
              </h4>

              <div class="inspection-graph-canvas">
                <canvas baseChart [data]="inspectionEvidences" type="pie">
                </canvas>
              </div>
            </article>
          }
        </div>

        @if (inspectionDegrees) {
          <article class="inspection-graph">
            <h4 class="inspection-graph-title">
              Gráfica de Tendencia de Plaga vs Grado de Infestación
            </h4>

            <canvas baseChart [data]="inspectionDegrees" type="line">
            </canvas>
          </article>
        }
      </section>
    }

    <div class="section">
      <h3 class="section-title">
        Tendencias de plagas
      </h3>

      <section class="plagues-graphs-body">
        <div class="plagues-graphs-trend">
          <div class="select-group">
            <label for="select-plague" class="select-label">
              Selecciona una plaga
            </label>

            <select
              name="select-plague"
              id="select-plague"
              class="select"
              (change)="onSelectPlague($event)"
            >
              <option value="" selected>
                Selecciona una plaga
              </option>

              @for (plague of plaguesList; track plague.id) {
                <option [value]="plague.id">
                  {{ plague.name }}
                </option>
              }
            </select>
          </div>

          @if (trendPlague) {
            <article class="trend-graph">
              <canvas baseChart [data]="trendPlague" type="line">
              </canvas>
            </article>
          } @else {
            <p class="no-trend-results">
              No hay tendencias de plagas
            </p>
          }
        </div>

        @if (!(
          conditionCategories == null &&
          conditionCleanings == null
        )) {
          <div class="plagues-graphs-pies">
            @if (conditionCategories) {
              <article class="condition-graph">
                <h4 class="condition-graph-title">
                  Proporción de Tipo de Condiciones
                </h4>

                <div class="plague-graph-container">
                  <canvas baseChart [data]="conditionCategories" type="pie">
                  </canvas>
                </div>
              </article>
            }

            @if (conditionCleanings) {
              <article class="condition-graph">
                <h4 class="condition-graph-title">
                  Proporción de Evidencias en Inspecciones
                </h4>

                <div class="plague-graph-container">
                  <canvas baseChart [data]="conditionCleanings" type="pie">
                  </canvas>
                </div>
              </article>
            }
          </div>
        }
      </section>
    </div>

    <div class="section">
      <h3 class="section-title">
        Tendencias de productos
      </h3>

      <section class="products-graphs-body">
        <div class="products-graphs-trend">
          <div class="select-group">
            <label for="select-product" class="select-label">
              Selecciona un producto
            </label>

            <select
              name="select-product"
              id="select-product"
              class="select"
              (change)="onSelectProduct($event)"
            >
              <option value="" selected>
                Selecciona un producto
              </option>

              @for (product of productsList; track product.id) {
                <option [value]="product.id">
                  {{ product.name }}
                </option>
              }
            </select>
          </div>

          @if (trendProduct) {
            <article class="trend-graph">
              <canvas baseChart [data]="trendProduct" type="line">
              </canvas>
            </article>
          } @else {
            <p class="no-trend-results">
              No hay tendencias de productos
            </p>
          }
        </div>

        @if (!(
          productApplications == null &&
          products == null &&
          productDoses == null
        )) {
          <div class="products-graphs-pies">
            @if (productApplications) {
              <article class="product-graph">
                <h4 class="product-graph-title">
                  Porcentaje de Tipo de Tipo de Aplicación
                </h4>

                <div class="product-graph-container">
                  <canvas baseChart [data]="productApplications" type="pie">
                  </canvas>
                </div>
              </article>
            }

            @if (products) {
              <article class="product-graph">
                <h4 class="product-graph-title">
                  Porcentaje de Productos
                </h4>

                <div class="product-graph-container">
                  <canvas baseChart [data]="products" type="pie">
                  </canvas>
                </div>
              </article>
            }

            @if (productDoses) {
              <article class="product-graph">
                <h4 class="product-graph-title">
                  Porcentaje de Dosis
                </h4>

                <div class="product-graph-container">
                  <canvas baseChart [data]="productDoses" type="pie">
                  </canvas>
                </div>
              </article>
            }
          </div>
        }
      </section>
    </div>

    @if (stationsKeys && stationsKeys.length > 0) {
      <section class="section">
        <h3 class="section-title">
          Monitoreo en estaciones
        </h3>

        @for (stationKey of stationsKeys; track $index) {
          <div class="stations-key-container">
            <h4 class="stations-key-title">
              Proporciones en {{ stationKey }}
            </h4>

            <div class="stations-graphs">
              @if (stationActivities[stationKey]) {
                <article class="station-graph">
                  <h4 class="station-graph-title">
                    Porcentaje de Actividad en {{ stationKey }}
                  </h4>

                  <canvas baseChart [data]="stationActivities[stationKey]" type="pie">
                  </canvas>
                </article>
              }

              @if (stationConditions[stationKey]) {
                <article class="station-graph">
                  <h4 class="station-graph-title">
                    Porcentaje de Condiciones en {{ stationKey }}
                  </h4>

                  <canvas baseChart [data]="stationConditions[stationKey]" type="pie">
                  </canvas>
                </article>
              }

              @if (stationPlagues[stationKey]) {
                <article class="station-graph">
                  <h4 class="station-graph-title">
                    Plagas en {{ stationKey }}
                  </h4>

                  <canvas baseChart [data]="stationPlagues[stationKey]" type="pie">
                  </canvas>
                </article>
              }
            </div>
          </div>
        }
      </section>
    }

    @if (trendStation) {
      <div class="section">
        <h3 class="section-title">
          Tendencias de estaciones
        </h3>

        <article class="trend-graph">
          <canvas baseChart [data]="trendStation" type="line">
          </canvas>
        </article>
      </div>
    }
  </div>
</app-layout>
