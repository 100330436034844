<div class="avatar-container">
  <img
    src="/img/avatar.png"
    alt="Avatar de usuario"
    class="avatar"
  />

  <section class="actions-section">
    <p class="user-info">
      {{ profile.user.first_name }} {{ profile.user.last_name }}
    </p>

    <div class="actions">
      <button
        class="button logout-button"
        title="Cerrar sesión"
        (click)="logout()"
      >
        <app-logout-icon [color]="'#fff'"></app-logout-icon>
      </button>

      <button
        class="button change-password-button"
        title="Cambiar contraseña"
        (click)="changePassword()"
      >
        <app-password-icon [color]="'#fff'"></app-password-icon>
      </button>
    </div>
  </section>
</div>
